<template>
  <MaskView
    borderStyle="border: 1px solid #d7d7d7"
    outerBorderStyle="1px dashed #02a7f0"
    :background-color="item.status === 1 ? `rgba(242, 242, 242, 1)` : ''"
  >
    <div class="position-relative w-100 h-100">
      <div class="position-absolute img-wrap">
        <img
          style="width: 90%; height: 90%; object-fit: contain"
          :src="item.deviceImage"
        />
        <p class="productName">{{ item.productSeriesName }}</p>
      </div>

      <div class="status position-absolute" v-if="item.status == 1">
        <b-badge class="custom-badge" href="#" variant="danger">Disable</b-badge>
      </div>
    </div>
    <template #top>
      <div class="vo-row align-items-center vo-gap-4 justify-content-end p-2">
        <img
          v-if="item.status == 0"
          src="@/assets/enable.svg"
          class="cursor"
          @click="clickStatus"
        />
        <img
          v-if="item.status == 1"
          src="@/assets/disable.svg"
          class="cursor"
          @click="clickStatus"
        />
        <img
          src="@/assets/view.svg"
          class="cursor"
          @click="clickDetail"
        />
        <img
          src="@/assets/edit.svg"
          class="cursor"
          @click="clickEdit"
        />
      </div>
    </template>
    <template #bottom>
      <div
        class="vo-row align-items-center justify-content-around flex-wrap mb-1 ml-1 mr-1"
        style="gap: 10px"
      >
        <b-button style="flex: 1" variant="info" @click="clickBrandSku"
          >Brand SKU
        </b-button>
        <b-button style="flex: 1" variant="info" @click="clickBatch"
          >Batch
        </b-button>
      </div>
    </template>
  </MaskView>
</template>

<script>
import MaskView from "@/components/MaskView.vue";

export default {
  name: "BrandItem",
  components: { MaskView },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {},
  methods: {
    clickStatus() {
      this.$emit("clickStatus");
    },
    clickDetail(){
      this.$emit("clickDetail");
    },
    clickEdit(){
      this.$emit("clickEdit");
    },
    clickBatch(){
      this.$emit("clickBatch");
    },
    clickBrandSku(){
      this.$emit("clickBrandSku");
    }
  },
};
</script>

<style scoped lang="scss">
.status {
  top: 0px;
  left: 0;
  font-size: 20px;
}

.img-wrap {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

::v-deep .badge {
  border-radius: 0;
}

.operate-btn {
  width: 80px;
}
.productName {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
}

.disableBox {
  background-color: rgba(242, 242, 242, 1);
}

.custom-badge{
  height: 30px; /* 设置高度 */
  line-height: 30px; /* 行高与高度相同，使文本垂直居中 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  text-align: center; /* 确保文本居中对齐 */
  margin-top: 12px;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MaskView",
    {
      attrs: {
        borderStyle: "border: 1px solid #d7d7d7",
        outerBorderStyle: "1px dashed #02a7f0",
        "background-color":
          _vm.item.status === 1 ? "rgba(242, 242, 242, 1)" : ""
      },
      scopedSlots: _vm._u([
        {
          key: "top",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center vo-gap-4 justify-content-end p-2"
                },
                [
                  _vm.item.status == 0
                    ? _c("img", {
                        staticClass: "cursor",
                        attrs: { src: require("@/assets/enable.svg") },
                        on: { click: _vm.clickStatus }
                      })
                    : _vm._e(),
                  _vm.item.status == 1
                    ? _c("img", {
                        staticClass: "cursor",
                        attrs: { src: require("@/assets/disable.svg") },
                        on: { click: _vm.clickStatus }
                      })
                    : _vm._e(),
                  _c("img", {
                    staticClass: "cursor",
                    attrs: { src: require("@/assets/view.svg") },
                    on: { click: _vm.clickDetail }
                  }),
                  _c("img", {
                    staticClass: "cursor",
                    attrs: { src: require("@/assets/edit.svg") },
                    on: { click: _vm.clickEdit }
                  })
                ]
              )
            ]
          },
          proxy: true
        },
        {
          key: "bottom",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "vo-row align-items-center justify-content-around flex-wrap mb-1 ml-1 mr-1",
                  staticStyle: { gap: "10px" }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { variant: "info" },
                      on: { click: _vm.clickBrandSku }
                    },
                    [_vm._v("Brand SKU ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticStyle: { flex: "1" },
                      attrs: { variant: "info" },
                      on: { click: _vm.clickBatch }
                    },
                    [_vm._v("Batch ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "position-relative w-100 h-100" }, [
        _c("div", { staticClass: "position-absolute img-wrap" }, [
          _c("img", {
            staticStyle: {
              width: "90%",
              height: "90%",
              "object-fit": "contain"
            },
            attrs: { src: _vm.item.deviceImage }
          }),
          _c("p", { staticClass: "productName" }, [
            _vm._v(_vm._s(_vm.item.productSeriesName))
          ])
        ]),
        _vm.item.status == 1
          ? _c(
              "div",
              { staticClass: "status position-absolute" },
              [
                _c(
                  "b-badge",
                  {
                    staticClass: "custom-badge",
                    attrs: { href: "#", variant: "danger" }
                  },
                  [_vm._v("Disable")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }